import { Button, Col, Form, Input, Row, Typography } from "antd"
import React, { FC, useState } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../components/app"
import { FirstVisitModal } from "./FirstVisitModal"
import Line from "../../shared/ui/Line"
import "./index.less"

const LogIn: FC = () => {
  const [loading, setLoading] = useState(false)

  const { login, loginError } = useAuth()

  const onFinish = async (values: { email: string; password: string }) => {
    setLoading(true)
    const email = values.email
    const password = values.password

    await login({ email, password })
    setLoading(false)    
  }

  return (
    <React.Fragment>
      <div className={"both-error"} style={{ color: "#F6494B" }}>
        {loginError && (
          <div className={"error-text"}>
            <div className={"red-small-circle"} />
            {loginError}
          </div>
        )}
      </div>
      <Typography style={{ marginTop: "18px" }} className={"auth-title"}>
        Log In
      </Typography>
      <Form layout={"vertical"} className={"auth-form"} onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <div className={"link-forgot-password-wrap"}>
              <p className={"link-forgot-password"}>Email</p>
            </div>
            <Form.Item name={"email"} rules={[{ type: "email", message: "Enter the valid email", required: true }]}>
              <Input size={"large"} placeholder={"Enter email"} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col span={24}>
            <div className={"link-forgot-password-wrap"}>
              <p className={"link-forgot-password"}>Password</p>
              <Link to={"/email-confirm"} className={"link-forgot-password"}>
                Forgot password?
              </Link>
            </div>
            <Form.Item name={"password"} rules={[{ required: true }]}>
              <Input.Password size={"large"} placeholder={"Enter password"} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <Form.Item>
              <Button loading={loading} type={"primary"} size={"large"} htmlType={"submit"} block>
                Log In
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Line margin={"48px 0"} />
      <Row>
        <p className={"text-sign-up"}>{"Don't have an account?"}</p>
        <Link to={"/sign-up"} className={"link-sign-up"}>
          Sign up
        </Link>
      </Row>  
      <FirstVisitModal />
    </React.Fragment>
  )
}

export default LogIn
