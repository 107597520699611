import { useEffect } from "react"

const GoogleTagManager = () => {
  const GTM = import.meta.env.WEBSITE_GTM_ID

  useEffect(() => {
    if (!GTM) {
      console.warn("GTM ID is not set.")
      return
    }

    const script = document.createElement("script")
    script.textContent = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM}');
    `
    document.head.appendChild(script)

    const noscript = document.createElement("noscript")
    const iframe = document.createElement("iframe")

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM}`
    iframe.height = "0"
    iframe.width = "0"
    iframe.style.display = "none"
    iframe.style.visibility = "hidden"

    noscript.appendChild(iframe)
    document.body.insertBefore(noscript, document.body.firstChild)

    return () => {
      document.head.removeChild(script)
      document.body.removeChild(noscript)
    }
  }, [])

  return null
}

export default GoogleTagManager
