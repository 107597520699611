import { FC, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSendEmailMutation } from "src/graphql"
import { ModalComponent } from "../../components/elements/modals/ModalComponent"

export const FirstVisitModal: FC = () => {
  const [sendEmail] = useSendEmailMutation()

  const [isModal, setModal] = useState(false)

  const navigate = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const verified = searchParams.get("verified")

  useEffect(() => {
    const isVerifiedMessageSended = localStorage.getItem("verified")
    const email = localStorage.getItem("email")

    if (verified && email && !isVerifiedMessageSended) {
      sendEmail({ variables: { type: "authorization-was-successful", email } }).catch(err => console.log(err))

      setModal(true)
      localStorage.setItem("verified", "true")
    }
  }, [sendEmail, verified])

  return (
    <ModalComponent
      visible={isModal}
      setVisibility={setModal}
      title={"Your account has been successfully verified"}
      subtitle={"Now you are ready to set up your account"}
      icon={"done"}
      leftBtn={{ label: "Back to site", onClick: () => navigate("/home") }}
      rightBtn={{ label: "Start", onClick: () => setModal(false) }}
      titlePadding={25}
    />
  )
}
