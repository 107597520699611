import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../components/app";
import LogIn from "./index";

const LoginHandler = () => {
  const { user } = useAuth();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get("redirect") || "/companies";

  if (user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <LogIn />;
};

export default LoginHandler;
